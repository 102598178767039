.customer-meeting {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    &__controls {
        .button {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            margin: 0 10px;
        }
        display: flex;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        justify-content: center;
        z-index: 1;
    }

    &__remote {
        display: flex;
        position: absolute;
        top: 20px;
        right: 10px;
        z-index: 1;
        width: auto;
        flex-direction: column;

        button {
            background: black;
        }

        canvas,
        video {
            flex: 1 1 0;
            min-width: 0;
            background-color: #000;
            max-height: 20vh;
            max-width: 30vw;
        }
    }

    .selected-video {
        max-height: 100%;
        // max-height: calc(100vh - 50px);
        width: max-content;
        width: -webkit-fill-available;
        max-width: 100vw;
    }

    &__switch,
    &__blur {
        background: #fff;
    }

    &__end {
        background: #ff381b;
    }
}
