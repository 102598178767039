.add-participant {
    display: flex;
    align-items: flex-end;
    flex: 1;
    padding: 20px;

    &__add {
        margin-left: 10px;
        padding: 0 20px;
        width: auto;

        &.disabled {
            background: #9dc1df;
        }
    }

    .input {
        flex: 1;
    }
}
