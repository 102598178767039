.loading {
    background: #e6e6e6;
    color: #a0a0a0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    flex: 1;
}
