.confirm-disconnect {
    .popup {
        max-width: 90vw;
    }
    &__buttons {
        margin-top: 20px;
        display: flex;
    }

    &__confirm {
        margin-left: 10px;
        width: 100%;
        height: 40px;
        font-size: 16px;
    }
}
