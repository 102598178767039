.terms {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__body {
        flex: 1;
    }

    &__continue {
        padding-top: 20;
    }
}
