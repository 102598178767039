.agent-meeting {
    position: relative;
    background: #e6e6e6;
    padding: 20px 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #a0a0a0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__remote {
        display: flex;
        height: 100%;
        width: 100%;

        canvas,
        video {
            flex: 1 1 0;
            min-width: 0;
        }
    }

    &__local {
        position: absolute;
        bottom: 30px;
        right: 30px;
        display: flex;
        z-index: 2;
        canvas,
        video {
            object-position: left;
        }

        canvas {
            max-height: 128px;
            width: auto;
        }
    }

    &__controls {
        .button {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__blur {
        background: #fff;
    }

    &__end {
        background: #ff381b;
    }
}
