.help {
    &__section {
        margin: 10px 0;
        display: flex;
        align-items: center;
    }

    &__icon {
        min-width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 20px;

        p {
            margin: 0;
        }
    }

    &__end {
        background: red;
    }
}
