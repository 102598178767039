.customer-button {
    background: #010a40;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;

    &.alt {
        outline: 1px solid #010a40;
        background: white;
        color: #010a40;
    }
}
