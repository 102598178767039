.survey {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__body {
        flex: 1;
    }

    &__continue {
        padding-top: 20;
    }

    &__options {
        margin-top: 20px;
        > button {
            margin: 5px 0;
        }
    }

    &__ranking {
        display: flex;
        margin: 5px 0;
    }

    &__number {
        color: #fff;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        background: #183a66;
        display: flex;
    }

    textarea {
        width: 100%;
        height: 200px;
        background: #e6e6e6;
        border: none;
        padding: 0;
        font-family: inherit;
        padding: 10px;
    }
}
