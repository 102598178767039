.survey-option {
    width: 100%;
    padding: 12px;
    text-align: left;
    &.selected {
        background: #010a40;
        color: #fff;
    }
    background: rgba(1, 10, 64, 0.05);
    color: #010a40;
}
